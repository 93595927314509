import React from 'react'
import Nav from '../components/Nav'
import Hero from '../components/Hero'

import { graphql, useStaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image'
import Footer from '../components/Footer';
import Gallery from '../components/Gallery';

export default function Leadership() {

    const data = useStaticQuery(
        graphql`
        query {
            file(relativePath: {eq: "Heros/our-team.jpg"}) {
                childImageSharp {
                  gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR)
                }
              }
          }
        `)

    const heroImage = data.file.childImageSharp.gatsbyImageData;

    return (
        <div>
            <Nav isActive="About"/>
            <Hero title="One Team" image={heroImage}/>
            <section className="px-6 py-4 md:py-10 bg-sphinx-background-white border-t-4 border-sphinx-accent">
                <div className="container mx-auto grid relative col-span-full max-w-7xl">
                    <div className="col-start-1 row-start-1 col-span-4 space-y-4 md:space-y-6">
                        <h1 className="text-4xl font-light font-montserrat text-sphinx-accent">Meet The Leadership Team</h1>
                        <h6 className="text-xl font-light font-sanspro text-gray-700 italic">Everything we do draws directly from this principle – keep it simple and get it right the first time.</h6>
                        {/* <p className="text-base font-sanspro text-gray-600 tracking-wide leading-loose font-light">Throughout nearly 40 years of home building success in and outside of the Lone Star State, the Sphinx Development Corporation has maintained a commitment to producing In doing so, Sphinx Development and its affiliates have created more than 1,440 affordable housing units within 10 projects in the State of Texas, and counting.</p> */}
                        <p className="text-base font-sanspro text-gray-600 tracking-wide leading-loose font-light">The Sphinx Development Corporation is managed by a crew of highly diverse talent  bearing an expertise and demonstrated history that spans across multiple decades in the real estate industry. Whether you're interested in partnering, securing housing for your family, or just getting to know us, we will find who you need to speak with. </p>
                    </div>
                    <StaticImage className="col-start-4 row-start-1 col-span-4 opacity-30 z-10" width={400} src="../images/Icons/logo-grayscale.png" quality={100}/>
                </div>
            </section>

            <section className="px-6 py-9 bg-sphinx-background-white-2">
                <div class="container mx-auto max-w-7xl space-y-24">
                    <div>
                        <h1 className="text-3xl font-light font-montserrat text-sphinx-accent mb-16">Accounting</h1>
                        <div className="grid grid-cols-1 md:grid-cols-3 place-items-center gap-y-8 md:gap-y-0 text-center">
                        
                            <div>
                                <StaticImage src="../images/texas.png" width={150} aspectRatio={1/1} quality={100}/>
                                <h6 className="mt-2 text-xl text-sphinx-accent font-montserrat">Robinson Njuguna</h6>
                                <p className="text-base text-gray-700 italic">Director / Comptroller</p>
                            </div>
                            <div>
                                <StaticImage src="../images/texas.png" width={150} aspectRatio={1/1} quality={100}/>
                                <h6 className="mt-2 text-xl text-sphinx-accent font-montserrat">Carol Mukuha</h6>
                                <p className="text-base text-gray-700 italic">Property Accountant</p>
                            </div>
                        </div>
                    </div>

            
                    <div>
                        <h1 className="text-3xl font-light font-montserrat text-sphinx-accent mb-16">Asset Preservation Management</h1>
                        <div className="grid grid-cols-1 md:grid-cols-2 place-items-center gap-y-8 md:gap-y-0 text-center">
                        
                            <div>
                                <StaticImage src="../images/texas.png" width={150} aspectRatio={1/1}/>
                                <h6 className="mt-2 text-xl text-sphinx-accent font-montserrat">Joseph Agummadu</h6>
                                <p className="text-base text-gray-700 italic">CFO - President & Porfolio Manager</p>
                            </div>
                            <div>
                                <StaticImage src="../images/texas.png" width={150} aspectRatio={1/1}/>
                                <h6 className="mt-2 text-xl text-sphinx-accent font-montserrat">Amara Oji</h6>
                                <p className="text-base text-gray-700 italic">Asset Preservation & Brand Manager</p>
                            </div>
                        </div>
                    </div>
                
                    <div>
                        <h1 className="text-3xl font-light font-montserrat text-sphinx-accent mb-16">Development Planning & Construction</h1>
                        <div className="grid grid-cols-1 md:grid-cols-3 place-items-center gap-y-8 md:gap-y-0 text-center">
                        
                            <div>
                                <StaticImage src="../images/texas.png" width={150} aspectRatio={1/1}/>
                                <h6 className="mt-2 text-xl text-sphinx-accent font-montserrat">Jay Oji</h6>
                                <p className="text-base text-gray-700 italic">CEO - President & Portfolio Developer</p>
                            </div>
                            <div>
                                <StaticImage src="../images/texas.png" width={150} aspectRatio={1/1}/>
                                <h6 className="mt-2 text-xl text-sphinx-accent font-montserrat">Adolphus Oji</h6>
                                <p className="text-base text-gray-700 italic">Construction & Project Management</p>
                            </div>
                            <div>
                                <StaticImage src="../images/texas.png" width={150} aspectRatio={1/1}/>
                                <h6 className="mt-2 text-xl text-sphinx-accent font-montserrat">Tekevwe Okobiah</h6>
                                <p className="text-base text-gray-700 italic">VP Portfolio Development & Asset Acquisitions</p>
                            </div>
                            
                        </div>
                    </div>
                

            
                    <div>
                        <h1 className="text-3xl font-light font-montserrat text-sphinx-accent mb-16">Human Resources</h1>
                        <div className="grid grid-cols-1 md:grid-cols-2 place-items-center text-center">
                        
                            <div>
                                <StaticImage src="../images/texas.png" width={150} aspectRatio={1/1}/>
                                <h6 className="mt-2 text-xl text-sphinx-accent font-montserrat">Nedra</h6>
                                <p className="text-base text-gray-700 italic">Administration Manager</p>
                            </div>
                            <div>
                                <StaticImage src="../images/texas.png" width={150} aspectRatio={1/1}/>
                                <h6 className="mt-2 text-xl text-sphinx-accent font-montserrat">Angel Milton</h6>
                                <p className="text-base text-gray-700 italic">HR, Admin & Operations</p>
                            </div>
                        </div>
                    </div>
                

          
                    <div>
                        <h1 className="text-3xl font-light font-montserrat text-sphinx-accent mb-16">Tax Credit Compliance & Regulation</h1>
                        <div className="grid grid-cols-1 md:grid-cols-2 place-items-center gap-y-8 md:gap-y-0 text-center">
                        
                            <div>
                                <StaticImage src="../images/texas.png" width={150} aspectRatio={1/1}/>
                                <h6 className="mt-2 text-xl text-sphinx-accent font-montserrat">Tameka Lampkin</h6>
                                <p className="text-base text-gray-700 italic">Program Compliance Monitor</p>
                            </div>
                            <div>
                                <StaticImage src="../images/texas.png" width={150} aspectRatio={1/1}/>
                                <h6 className="mt-2 text-xl text-sphinx-accent font-montserrat">Amara Oji</h6>
                                <p className="text-base text-gray-700 italic">Compliance Supervisor</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>

            
        </div>
    )
}
