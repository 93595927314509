import React from "react"

import {GatsbyImage, getImage } from 'gatsby-plugin-image'

export default function Hero(props) {

  const image = getImage(props.image);

    return (
        <div className="pt-16 h-hero grid">

          <GatsbyImage 
            style={{gridArea: "1/1"}}
            layout="fullWidth"
            image={image}
            loading="eager"
            />
        <div
        style={{
          gridArea: "1/1",
          position: "relative",
          placeItems: "center",
          display: "grid",
        }}
        className="bg-sphinx-blue bg-opacity-10"
      >
        <h1 className="text-center text-5xl text-sphinx-white font-montserrat font-medium leading-relaxed">{props.title}</h1>
        </div>
    </div>
    )
}
